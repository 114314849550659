
























































import { defineComponent } from "@vue/composition-api";
import { SplashUserType } from "@/types/typeDefinitions";
import { useUserAPI } from "@/api_connectors/user";
import router from "@/router";

export default defineComponent({
    name: "PickUserType",
    setup() {
        const { getUserData, setUserData } = useUserAPI()

        const selectUserType = async (userType: SplashUserType): Promise<void> => {
            const userData = await getUserData()

            if (!userData) return;

            userData.type = userType

            await setUserData(userData)

            await router.push({ name: 'WorldOfMusic' }).catch()
        }

        return {
            selectUserType,
            SplashUserType
        }
    }
})
